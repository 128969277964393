import './actionModal.scss'
import { useNavigate } from "react-router-dom"

const ActionModal = ({loading, newTicket, openActionModal, res, error}) => {
    const navigate = useNavigate();

    const redirectToHome = () => {
        navigate('/')
    }
    return (
        <div className="actionModal">
            <div className="actionModalContainer">
                {
                    loading ?
                        "Loading..."
                    :
                        error ?
                            <div className="actionModalWrap">
                                <ErrorContent error={error} openActionModal={openActionModal}/>
                            </div>
                        :
                            <div className="actionModalWrap">
                                <TicketContent newTicket={newTicket} redirectToHome={redirectToHome} />
                            </div>
                }
            </div>
        </div>
    )
}

const TicketContent = ({newTicket, redirectToHome}) => {
    return <>
        <div className="actionModalForm">
            <div className="actionModalTicketWrap">
                <div className="holes-top-ticket"></div>
                <div className="actionModalFormItem">
                    <div className="actionModalTitle">
                        Your Carehub Ticket
                    </div>
                </div>
                <div className="actionModalFormItem">
                    <h3>{newTicket.ticketQueueNumber}</h3>
                </div>
                <div class="holes-lower-ticket"></div>
            </div>
            <button className="actionModalBtn" onClick={ redirectToHome } >Close</button>
        </div>
    </>
}

const ErrorContent = ({error, openActionModal}) => {
    return <>
        <div className="actionModalTitle">
            <h3>That didn't work</h3>
        </div>
        <div className="actionModalForm">
            <div className="actionModalFormItem">
                <span>Contact System Administrator</span>
            </div>
            <div className="actionModalFormItem">
                <span>{error?.message}</span>
            </div>
            <button className="actionModalBtn" onClick={ ()=> openActionModal(false)}>Close</button>
        </div>
    </>
}

export default ActionModal
