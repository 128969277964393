import './settings.css'
import { useRef, useState, useContext } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEyeSlash,
    faEye
  } from "@fortawesome/free-solid-svg-icons";
import carelonCarehubLogo from "../../assets/images/carelon_carehub_logo.png"
import { LocationContext } from '../../context/LocationContext'
import { ACTION_TYPES } from "../../utils/definitions.js"
import { requestUrl } from '../../utils/requestMethods.js'
import useFetch from '../../hooks/useFetch';

const Settings = () => {
    const query = `/sites`
    const { data } = useFetch(query);

    const [ credentials, setCredentials ] = useState({
        domainId: 'Administrator',
        password: undefined,
    })
    const [ errorLogin, setErrorLogin ] = useState(false)
    const [ errorLoginMessage, setErrorLoginMessage ] = useState('')
    const { dispatch } = useContext(LocationContext);
    const locationRef = useRef()
    const passwordRef = useRef()
    const [ isPasswordHidden, setIsPasswordHidden ] = useState(true)

    const handlePassword = e => {
        let input = e.target.value
        setCredentials(prev => ({...prev, [e.target.id]: input }))
    }

    const togglePassword = e => {
        e.preventDefault();
        passwordRef.current.type = isPasswordHidden ? 'text' : 'password'
        setIsPasswordHidden(!isPasswordHidden)
    }

    const handleChangeLocation = () => {

    }
    const processChangeLocation = async e => {
        e.preventDefault();
        dispatch({type:ACTION_TYPES.START});
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: 'same-origin'
            }
            let location = {
                location: locationRef.current.value
            }
            const res = await requestUrl.post("/auth/login", credentials, config)
            dispatch({ type:ACTION_TYPES.SUCCESS, payload: location })
            window.location.pathname = '/'
        } catch (error) {
            if (error.response?.data !== undefined) 
                dispatch({type:ACTION_TYPES.FAILURE, payload: error.response?.data})
            else 
                dispatch({type:ACTION_TYPES.FAILURE, payload: error})
            setErrorLogin(true)
            setErrorLoginMessage('Invalid Credentials')
        }
    }
    return (
        <div className="login">
            <div className="upperSection">
                <div className="loginTitles">
                    <img src={carelonCarehubLogo} alt="" className="loginCompanyLogo"/>
                    <span className="appName">Change Location Settings</span>
                </div>
            </div>
            <div className="lowerSection">
                
                <div className="loginContainer">
                    <select ref={locationRef} className="locationList" id="locationList" onChange={handleChangeLocation}>
                        {
                            data && data.map( (item) => (
                            <option key={item._id} value={item._id}>{item.title}</option>
                            ))                        
                        }
                    </select>
                    <div className="loginPasswordWrap">
                        <input 
                            type="password" 
                            placeholder="Password" 
                            id="password" 
                            className="loginInput password" 
                            onChange={handlePassword}
                            ref={passwordRef}
                        />
                        <FontAwesomeIcon icon={ isPasswordHidden === true ? faEyeSlash : faEye} className="loginEye" onClick={togglePassword}/>
                    </div>
                    <button className="loginButton" onClick={processChangeLocation}>Apply</button>
                    { errorLogin && <span className="loginError">{errorLoginMessage}</span>}
                </div>
            </div>
        </div>
    )
}

export default Settings
