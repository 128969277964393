import './feedbackContent.css'
import { useState, useRef } from 'react';
import { requestUrl } from '../../utils/requestMethods.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons"
import { faFaceTired, faFaceFrown, faFaceMeh, faFaceSmile, faFaceGrinBeam } from "@fortawesome/free-regular-svg-icons"
import FeedbackModal from '../FeedbackModal/FeedbackModal';
import useFetch from '../../hooks/useFetch';
import { getSiteName } from '../../services/tickets.js'

const FeedbackContent = ({ticket, invalidMsg, isIMS, ticketQueueNumber}) => {
    const query = `/sites`
    const { data } = useFetch(query);
    const siteLocationRef = useRef()
    const [feedback, setFeedback] = useState({
        overallExp: '0',
        waitTime: '0',
        techSupportOptions: '0',
        customerExp: '0',
        feedbackAdditionalComments: '',
        createdDate: new Date()
    })
    const [feedbackStatus, setFeedbackStatus] = useState(false)
    const [overallExp, setOverallExp] = useState('0')
    const [waitTime, setWaitTime] = useState('0')
    const [techSupportOptions, setTechSupportOptions] = useState('0')
    const [customerExp, setCustomerExp] = useState('0')
    const [isValidated, setIsValidated] = useState(false)
    const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('')

    const handleInput = e => {
        switch (e.target.name) {
            case 'overallExp':
                setOverallExp(e.target.value)
                break;
            case 'waitTime':
                setWaitTime(e.target.value)
                break;
            case 'techSupportOptions':
                setTechSupportOptions(e.target.value)
                break;
            case 'customerExp':
                setCustomerExp(e.target.value)
                break;
            default:
                break;
        }
        setFeedback(prev => ({
            ...prev, [e.target.name]: e.target.value
        }))
        setFeedbackStatus(true)
        validateFeedbackForm()
    }

    const validateFeedbackForm = () => {
        if (feedback.overallExp !== '0' && 
        feedback.waitTime !== '0' &&
        feedback.techSupportOptions !== '0' &&
        feedback.setCustomerExp !== '0') {
            setIsValidated(true)
        }
        else {
            setIsValidated(false)
        }
    }

    const handleCancel = e => {
        window.location.reload();
    }

    const handleSubmit = async e => {
        e.preventDefault();
        let res = null
        try {
            let config = {
                headers: {
                  'Content-Type': 'application/json',
                },
                withCredentials: 'same-origin'
            }
            if (isIMS) {
                const siteName = await getSiteName(siteLocationRef.current.value)
                const payload = {
                    imsTicketNumber: ticketQueueNumber,
                    feedbackStatus: feedbackStatus,
                    siteLocation: siteName,
                    feedback: feedback
                }
                res = await requestUrl.post(`/tickets/feedbackIMS`, payload, config)
            }
            else {
                const payload = {
                    imsTicketNumber: ticketQueueNumber,
                    feedbackStatus: feedbackStatus,
                    feedback: feedback
                }
                res = await requestUrl.put(`/tickets/${ticket[0]?._id}`, payload, config)
            }
            setModalMessage('Your feedback has been submitted successfully. Thank you!')
            setOpenFeedbackModal(true)
        } catch (error) {
            setModalMessage("There was an error in submitting your feedback. Please try again.")
            setOpenFeedbackModal(true)
            console.log(error)
        }
    }
    
    return (
        <div className="feedbackContentContainer">
                { 
                    (ticket.length === 0 && !isIMS) || ticket[0]?.ticketStatus === "NEW" || (ticket[0]?.ticketStatus === "CLOSED" && ticket[0]?.feedbackStatus === true)?
                        <div className="surveyErrorMsg"> {invalidMsg} </div> :
                            <> 
                                { (ticket.length !== 0 || isIMS) ? 
                                    <div className="surveyContentWrapper" >
                                        <div className="surveyVerified">
                                            Care Hub Ticket Verified 
                                            <div className='surveyVerifiedCheck'>
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                            </div>
                                        </div>
                                        
                                        <div className="surveyQuestionsWrapper">
                                            
                                            <div className="surveyItemWrapper">
                                                <div className="surveyCategory">
                                                    
                                                </div>
                                                <div className="surveyItem">
                                                    Very Dissatisfied
                                                </div>
                                                <div className="surveyItem">
                                                    Dissatisfied
                                                </div>
                                                <div className="surveyItem">
                                                    Neutral
                                                </div>
                                                <div className="surveyItem">
                                                    Satisfied
                                                </div>
                                                <div className="surveyItem">
                                                    Very Satisfied
                                                </div>
                                            </div>
                                            <div className="surveyItemWrapper first">
                                                <div className="surveyCategory">
                                                    
                                                </div>
                                                <div className="surveyItem">
                                                    <FontAwesomeIcon className="surveyEmoticon" icon={faFaceTired} />
                                                </div>
                                                <div className="surveyItem">
                                                    <FontAwesomeIcon className="surveyEmoticon" icon={faFaceFrown} />
                                                </div>
                                                <div className="surveyItem">
                                                    <FontAwesomeIcon className="surveyEmoticon" icon={faFaceMeh} />
                                                </div>
                                                <div className="surveyItem">
                                                    <FontAwesomeIcon className="surveyEmoticon" icon={faFaceSmile} />
                                                </div>
                                                <div className="surveyItem">
                                                    <FontAwesomeIcon className="surveyEmoticon" icon={faFaceGrinBeam} />
                                                </div>
                                            </div>
                                            
                                                {
                                                    (isIMS ? 
                                                    <>
                                                    <div className="surveyItemWrapper">
                                                        <div className="surveyCategory">
                                                            Site Location
                                                        </div>
                                                        <div className="surveyItem">
                                                            <select className='siteSelect' ref={siteLocationRef} >
                                                            {
                                                                data && data.map( (item) => (
                                                                <option key={item._id} value={item._id}>{item.title}</option>
                                                                ))                        
                                                            }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    </> :
                                                    '')
                                                }
                                                
                                            <div className="surveyItemWrapper">
                                                <div className="surveyCategory">
                                                    How was your overall experience?
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="overallExp"
                                                        value="1"
                                                        checked={overallExp === "1"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="overallExp"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceTired} /> Very Dissatisfied</label>
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="overallExp"
                                                        value="2"
                                                        checked={overallExp === "2"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="overallExp"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceFrown} /> Dissatisfied</label>
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="overallExp"
                                                        value="3"
                                                        checked={overallExp === "3"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="overallExp"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceMeh} /> Neutral</label>
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="overallExp"
                                                        value="4"
                                                        checked={overallExp === "4"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="overallExp"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceSmile} /> Satisfied</label>
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="overallExp"
                                                        value="5"
                                                        checked={overallExp === "5"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="overallExp"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceGrinBeam} /> Very Satisfied </label>
                                                </div>
                                            </div>
                                            <div className="surveyItemWrapper">
                                                <div className="surveyCategory">
                                                    Are you satisfied with your wait time?
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="waitTime"
                                                        value="1"
                                                        checked={waitTime === "1"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="waitTime"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceTired} />  Very Dissatisfied</label>
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="waitTime"
                                                        value="2"
                                                        checked={waitTime === "2"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="waitTime"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceFrown} />  Dissatisfied</label>
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="waitTime"
                                                        value="3"
                                                        checked={waitTime === "3"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="waitTime"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceMeh} />  Neutral</label>
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="waitTime"
                                                        value="4"
                                                        checked={waitTime === "4"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="waitTime"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceSmile} />  Satisfied</label>
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="waitTime"
                                                        value="5"
                                                        checked={waitTime === "5"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="waitTime"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceGrinBeam} />  Very Satisfied</label>
                                                </div>
                                            </div>
                                            <div className="surveyItemWrapper">
                                                <div className="surveyCategory">
                                                    Are you happy with the technical support options offered?
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="techSupportOptions"
                                                        value="1"
                                                        checked={techSupportOptions === "1"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="techSupportOptions"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceTired} />  Very Dissatisfied</label>
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="techSupportOptions"
                                                        value="2"
                                                        checked={techSupportOptions === "2"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="techSupportOptions"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceFrown} />  Dissatisfied</label>
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="techSupportOptions"
                                                        value="3"
                                                        checked={techSupportOptions === "3"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="techSupportOptions"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceMeh} />  Neutral</label>
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="techSupportOptions"
                                                        value="4"
                                                        checked={techSupportOptions === "4"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="techSupportOptions"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceSmile} />  Satisfied</label>
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="techSupportOptions"
                                                        value="5"
                                                        checked={techSupportOptions === "5"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="techSupportOptions"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceGrinBeam} />  Very Satisfied</label>
                                                </div>
                                            </div>
                                            <div className="surveyItemWrapper">
                                                <div className="surveyCategory">
                                                    Are you pleased with your customer experience?
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="customerExp"
                                                        value="1"
                                                        checked={customerExp === "1"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="customerExp"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceTired} />  Very Dissatisfied</label>
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="customerExp"
                                                        value="2"
                                                        checked={customerExp === "2"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="customerExp"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceFrown} />  Dissatisfied</label>
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="customerExp"
                                                        value="3"
                                                        checked={customerExp === "3"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="customerExp"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceMeh} />  Neutral</label>
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="customerExp"
                                                        value="4"
                                                        checked={customerExp === "4"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="customerExp"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceSmile} /> Satisfied</label>
                                                </div>
                                                <div className="surveyItem">
                                                    <input
                                                        className="surveyRadio"
                                                        type="radio"
                                                        name="customerExp"
                                                        value="5"
                                                        checked={customerExp === "5"}
                                                        onChange={handleInput}
                                                    />
                                                    <label htmlFor="customerExp"><FontAwesomeIcon className="surveyEmoticon" icon={faFaceGrinBeam} />  Very Satisfied</label>
                                                </div>
                                            </div>
                                            <div className="surveyItemWrapper">
                                                <div className="surveyCategory">
                                                    Please enter any additional comments:
                                                </div>
                                                <div className="surveyItem">
                                                <textarea id='feedbackAdditionalComments' name="feedbackAdditionalComments" rows='3' cols='26' onKeyUp={handleInput} onChange={handleInput}></textarea>
                                                </div>
                                            </div>
                                            <div className="surveyItemWrapper">
                                                <div className="surveyItem actionButtons">
                                                    <button className="feedbackCancelBtn" onClick={handleCancel}>Cancel</button>
                                                    <button className="feedbackSubmitBtn" onClick={handleSubmit} disabled={!isValidated}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ''
                                }
                                {
                                    openFeedbackModal && (
                                        <FeedbackModal 
                                            openFeedbackModal={setOpenFeedbackModal} 
                                            modalMessage={modalMessage}
                                        />
                                )}
                            </>
                }

            
        </div>
    )
}

export default FeedbackContent
