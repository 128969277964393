import { createContext, useReducer } from 'react'
import { ACTION_TYPES } from '../utils/definitions.js'

const INITIAL_STATE = {
    ticket: null,
    loading: false,
    error: null,
}

export const TicketContext = createContext(INITIAL_STATE);

const TicketReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.START:
            return {
                ticket: null,
                loading: true,
                error: null,
            };
        case ACTION_TYPES.SUCCESS:
            return {
                ticket: action.payload,
                loading: false,
                error: null,
            };
        case ACTION_TYPES.FAILURE:
            return {
                ticket: null,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const TicketContextProvider = ({ children }) => {
    const [ state, dispatch ] = useReducer(TicketReducer, INITIAL_STATE);

    return (
        <TicketContext.Provider 
            value={
                {
                    ticket: state.ticket,
                    loading: state.loading,
                    error: state.error,
                    dispatch,
                }
            }
        >
            {children}
        </TicketContext.Provider>
    )
}