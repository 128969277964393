import { useContext, useState } from 'react'
import { AuthContext } from "../../context/AuthContext";
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import TopBar from '../../components/Topbar/Topbar'
import SideBar from '../../components/Sidebar/SideBar';
import UserOptions from '../../components/UserOptions/UserOptions';

const Admin = () => {
    const { user } = useContext(AuthContext)

    const [isSideBarOpen, setIsSideBarOpen] = useState(false)
    const toggleSideBar = () => {
        isSideBarOpen === true ? setIsSideBarOpen(false) : setIsSideBarOpen(true)
    }
    const [isUserOptionsOpen, setUserOptionsOpen] = useState(false)
    const toggleUserOptions = () => {
        isUserOptionsOpen == true ? setUserOptionsOpen(false) : setUserOptionsOpen(true)
    }
    return (
        <div>
            {
                user ? 
                <>
                    <TopBar toggleSideBar={toggleSideBar} toggleUserOptions={toggleUserOptions}/>
                    <SideBar isSideBarOpen={isSideBarOpen} toggleSideBar={toggleSideBar} />
                    <UserOptions isUserOptionsOpen={isUserOptionsOpen} toggleUserOptions={toggleUserOptions} />
                </>
                : ''
            }
            <Outlet />
        </div>
    )
}

export default Admin
