import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import './index.css';
import App from './App';
import { AuthContextProvider } from "./context/AuthContext";
import { LocationContextProvider } from "./context/LocationContext";
import { TicketContextProvider } from "./context/TicketContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <LocationContextProvider>
        <TicketContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </TicketContextProvider>
      </LocationContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);