import './ticketList.css'
import { useContext, useState } from 'react'
import useFetch from '../../hooks/useFetch.js'
import { AuthContext } from "../../context/AuthContext";
import getInfoOFAdmin from '../../utils/getInfoOfAdmin'
import TicketCard from '../../components/TicketCard/TicketCard'
import { requestUrl } from '../../utils/requestMethods.js'

const TicketList = ({ticketStatus}) => {
    const { user } = useContext(AuthContext)
    let department = getInfoOFAdmin(user, 'department')
    let role = getInfoOFAdmin(user, 'role')
    let userSiteId = user.siteId;
    const [siteName, setSiteName] = useState('')
    const getSiteName = async (userSiteId) => {
        const query = `/sites/${userSiteId}`
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: 'same-origin'
            }
            const res = await requestUrl.get(query, config)
            const { data } = res
            setSiteName(data.name)
        } catch (error) {
            console.log(error)
        }
    }
    let runFunction = getSiteName(userSiteId)
    const query = `/tickets/getTicketsByDepartment?department=${department}&siteId=${userSiteId}&ticketStatus=${ticketStatus}`
    const { data, loading, error } = useFetch(query)
    return ( 
            <div className='ticketListContainer'>
                <div className="ticketListHeader">
                    <span>{role} {ticketStatus === "NEW" ? "Active" : "Closed"} Tickets - {siteName}</span>
                </div>
                <div className="ticketListContent">
                    <div className="ticketListContentWrapper">
                        <div className="ticketListContentList">
                            {
                                loading ?
                                "Loading..."
                                :
                                error ?
                                <>
                                            <span>{error.message}</span><br/>
                                            <span>Contact System Administrator</span>
                                        </>
                                    :
                                    (Object.keys(data).length === 0) ?
                                    "No Tickets"
                                    :
                                    <>
                                                {data.map( (item) => (
                                                    <TicketCard key={item._id} ticketInfo={item} ticketStatus={ticketStatus}/>
                                                    ))}
                                            </> 
                            }
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default TicketList
