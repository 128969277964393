import './viewTicket.css'

import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCirclePlus,
    faCircleCheck
  } from "@fortawesome/free-solid-svg-icons";

import {
    TICKET_STATUS
} from "../../utils/definitions.js";

const Ticket = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [ticketInfo, setTicketInfo] = useState(location.state.ticketInfo);
    let start = new Date(ticketInfo.createdAt);
    let closeDate = new Date(ticketInfo.actionTaken?.performedDate)
    console.log(ticketInfo)
    const handleGoBack = () => {
        navigate(-1);
    };
    
    return (
        <div className="ticketInfoContainer">
            <div className="ticketInfoHeader">
                <span>Ticket Information</span>
            </div>
            <div className="ticketInfoWrapper">
                <div className="ticketInfoActionBox">
                    <h1 className="ticketInfoTitle">{ticketInfo.ticketQueueNumber}</h1>
                    <div className="ticketInfoActionBoxItemWrapper">
                        <div className="ticketInfoActionBoxItem">
                            <label>Status</label>
                            <div className="statusWrapper">
                            <span
                                className={
                                    ticketInfo.ticketStatus === TICKET_STATUS.CLOSED
                                    ? "ticketInfoActionBoxItemStatus green"
                                    : "ticketInfoActionBoxItemStatus"
                                }
                            >
                                {ticketInfo.ticketStatus}
                            </span>
                            <FontAwesomeIcon
                                icon={
                                    ticketInfo.ticketStatus === TICKET_STATUS.CLOSED
                                    ? faCircleCheck
                                    : faCirclePlus
                                }
                                className={
                                    ticketInfo.ticketStatus === TICKET_STATUS.CLOSED
                                    ? "statusWrapper green"
                                    : "statusWrapper"
                                }
                            />
                            </div>
                        </div>
                    </div>
                    <button className="ticketInfoDefaultBtn" onClick={handleGoBack}>
                        Back
                    </button>
                </div>
                <div className="ticketInfoRightPanel">
                    <div className="ticketInfoPanelContainer">
                        <div className="ticketInfoItemsWrapper">
                            <div className="ticketInfoItemsColumn">
                                <div className="ticketInfoItem">
                                    <label>Department</label>
                                    <span>{ticketInfo.department}</span>
                                </div>
                                <div className="ticketInfoItem">
                                    <label>Issue</label>
                                    <span>{ticketInfo.issue}</span>
                                </div>
                                <div className="ticketInfoItem">
                                    <label>{ticketInfo.additionalInformation !== '' ? 'Additional Information' : ''}</label>
                                    <span>{ticketInfo.additionalInformation}</span>
                                </div>
                                <div className="ticketInfoItem">
                                    <label>Date Created</label>
                                    <span>{`${format(start, "MMM dd yyyy")}`}</span>
                                    <span>{`${format(start, "p")}`}</span>
                                </div>
                                <div className="ticketInfoItem">
                                    <label>Care Hub Site</label>
                                    <span>{ticketInfo.siteId} hr/s</span>
                                </div>
                                
                            </div>
                            <div className="ticketInfoItemsColumn">
                                <div className="ticketInfoItem">
                                    <label>Submitted By</label>
                                    <span>{ticketInfo.lastName}, {ticketInfo.firstName}</span>
                                </div>
                                <div className="ticketInfoItem">
                                    <label>Employee Type</label>
                                    <span>{ticketInfo.isActive ? 'Carelon Associate' : 'Non-Carelon Associate'}</span>
                                </div>
                                <div className="ticketInfoItem">
                                    <label>E-mail</label>
                                    <span>{ticketInfo.email}</span>
                                </div>
                                <div className="ticketInfoItem">
                                    <label>{ticketInfo.isActive ? 'Domain ID' : ''}</label>
                                    <span>{ticketInfo.domainId}</span>
                                </div>
                                <div className="ticketInfoItem">
                                    <label>{ticketInfo.isActive ? 'LOB/Tower' : ''}</label>
                                    <span>{ticketInfo.lineOfBusinessOrTower}</span>
                                </div>
                            </div>
                            <div className="ticketInfoItemsColumn">
                                {
                                    ticketInfo.ticketStatus === TICKET_STATUS.CLOSED ?
                                    <>
                                        <div className="ticketInfoItem">
                                            <label>Activity Information</label>
                                        </div>
                                        <div className="ticketInfoItem">
                                            <label>Activity: </label>
                                            <span>{ticketInfo.actionTaken.action}</span>
                                        </div>
                                        <div className="ticketInfoItem">
                                            <label>Handled by: </label>
                                            <span>{ticketInfo.actionTaken.performedBy}</span>
                                        </div>
                                        <div className="ticketInfoItem">
                                            <label>Date: </label>
                                            <span>{`${format(closeDate, "MMM dd yyyy")}`}</span>
                                            <span>{`${format(closeDate, "p")}`}</span>
                                        </div>
                                    </>
                                    : 
                                    <>
                                        <div className="ticketInfoItem">
                                            <label>Activity Information</label>
                                            <span>Ticket is still open.</span>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="ticketInfoItemsColumn">
                                {
                                    ticketInfo.feedback['overallExp'] === '0' ? 
                                    <>
                                        <div className="ticketInfoItem">
                                            <label>Feedback Information</label>
                                            <span>No feedback received yet.</span>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="ticketInfoItem">
                                            <label>Feedback Information</label>
                                        </div>
                                        <div className="ticketInfoItem">
                                            <label>Overall Experience</label>
                                            <span>{ticketInfo.feedback['overallExp']} of 5</span>
                                        </div>
                                        <div className="ticketInfoItem">
                                            <label>Wait Time</label>
                                            <span>{ticketInfo.feedback['waitTime']} of 5</span>
                                        </div>
                                        <div className="ticketInfoItem">
                                            <label>Technical Support Options</label>
                                            <span>{ticketInfo.feedback['techSupportOptions']} of 5</span>
                                        </div>
                                        <div className="ticketInfoItem">
                                            <label>Customer Experience</label>
                                            <span>{ticketInfo.feedback['customerExp']} of 5</span>
                                        </div>
                                        <div className="ticketInfoItem">
                                            <label>{ ticketInfo.feedback['feedbackAdditionalComments'] === '' ? '' : 'Additional Comments'}</label>
                                            <span>{ticketInfo.feedback['feedbackAdditionalComments']}</span>
                                        </div>
                                    </>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ticket
