import { PureComponent } from 'react';
import './pieChart.css'
import {
    PieChart,
    Pie,
    Sector
} from "recharts";
import { forwardRef } from 'react';

const data = [
    { name: 'Corporate Support', value: 400 },
    { name: 'Finance', value: 300 },
    { name: 'Workforce', value: 300 },
    { name: 'Facilities', value: 200 },
];

  
const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    const fill = "#794CFF"
    return (
      <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} className="sectorLabel">
                {payload.name}
            </text>
        
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Ticket Count ${value}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(Percentage ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    )
};

export default class PieChartIssues extends PureComponent {
    state = {
        activeIndex: 0,
    };
    onPieEnter = (_, index) => {
        this.setState({
            activeIndex: index,
        });
    };
    render() {
        return (
            <PieChart width={550} height={550}>
              <Pie
                activeIndex={this.state.activeIndex}
                activeShape={renderActiveShape}
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                onMouseEnter={this.onPieEnter}
              />
            </PieChart>
        );
      }
}