import './App.css';
import { useContext } from 'react'
import { AuthContext } from "./context/AuthContext";
import Landing from './pages/Landing/Landing'
import {
  Routes,
  Route,
  Navigate
} from "react-router-dom"
import Settings from './pages/Settings/Settings';
import Admin from './pages/Admin/Admin';
import Ticket from './pages/Ticket/Ticket';
import NewTicket from './pages/Ticket/NewTicket';
import Login from './pages/Login/Login';
import Reports from './pages/Reports/Reports';
import TicketList from './pages/TicketList/TicketList';
import Feedback from './pages/Feedback/Feedback';
import Search from './pages/Search/Search';

function App() {
  const { user } = useContext(AuthContext)
  const deviceLocation = JSON.parse(localStorage.getItem('location'))

  return (
    <>
      <Routes>
        <Route path="/admin" element={ user ? <Admin /> : <Navigate to="/admin/login"/> }>
          <Route index element={<TicketList/>} />
          <Route path="/admin/tickets" element={ user ? <TicketList ticketStatus={"NEW"}/>: <Navigate to="/admin/login" /> } />
          <Route path="/admin/tickets/:id" element={ user ? <Ticket/>: <Navigate to="/admin/login" /> } />
          <Route path="/admin/search" element={ user ? <Search/>: <Navigate to="/admin/login" /> } />
          <Route path="/admin/archive" element={ user ? <TicketList ticketStatus={"CLOSED"}/>: <Navigate to="/admin/login" /> } />
          <Route path="/admin/reports" element={ user ? <Reports/>: <Navigate to="/admin/login" /> } />
        </Route>
        <Route path="/admin/login" element={ user ? <Navigate to="/admin"/> : <Login /> } />
        <Route path="/settings" element={ <Settings /> } />
        <Route path="/feedback" element={ <Feedback /> } />
        <Route path="/ticket/new" element={ <NewTicket /> } />
        <Route path="/" element={ deviceLocation !== null ? <Landing /> : <Navigate to="/settings" /> } />
      </Routes>
    </>
  );
}

export default App;
