import { createContext, useReducer, useEffect } from 'react'
import { ACTION_TYPES } from '../utils/definitions.js'

const INITIAL_STATE = {
    location: JSON.parse(localStorage.getItem('location')) || null,
    loading: false,
    error: null,
}

export const LocationContext = createContext(INITIAL_STATE);

const LocationReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.START:
            return {
                location: state.location,
                loading: true,
                error: null,
            };
        case ACTION_TYPES.SUCCESS:
            return {
                location: action.payload,
                loading: false,
                error: null,
            };
        case ACTION_TYPES.FAILURE:
            return {
                location: state.location,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const LocationContextProvider = ({ children }) => {
    const [ state, dispatch ] = useReducer(LocationReducer, INITIAL_STATE);

    useEffect( () => {
        localStorage.setItem('location', JSON.stringify(state.location))
    },[state.location])

    return (
        <LocationContext.Provider 
            value={
                {
                    location: state.location,
                    loading: state.loading,
                    error: state.error,
                    dispatch,
                }
            }
        >
            {children}
        </LocationContext.Provider>
    )
}