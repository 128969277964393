import './login.css'
import { useContext, useState, useRef } from "react"
import { AuthContext } from "../../context/AuthContext"
import { useNavigate } from "react-router-dom"
import { requestUrl } from '../../utils/requestMethods.js'
import carelonCarehubLogo from "../../assets/images/carelon_carehub_logo.png"
import { ACTION_TYPES } from "../../utils/definitions.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
    const [ credentials, setCredentials ] = useState({
        domainId: undefined,
        password: undefined,
    })
    const { loading, error, dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    const passwordRef = useRef()
    const [ isPasswordHidden, setIsPasswordHidden ] = useState(true)

    const handleChange = (e) => {
        let input = e.target.value
        if (e.target.id === 'domainId') input = input.toUpperCase()
        setCredentials(prev => ({...prev, [e.target.id]: input }))
    }

    const handleLogin = async e => {
        e.preventDefault();
        dispatch({type:ACTION_TYPES.START});
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: 'same-origin'
            }
            const res = await requestUrl.post("/auth/login", credentials, config)
            const userId = res.data._id
            dispatch({ type:ACTION_TYPES.SUCCESS, payload: res.data })
            navigate('/admin/tickets');
            //TODO: LOG INSTANCE
        } catch (error) {
            if (error.response?.data !== undefined) 
                dispatch({type:ACTION_TYPES.FAILURE, payload: error.response?.data})
                //TODO: LOG INSTANCE
            else 
                dispatch({type:ACTION_TYPES.FAILURE, payload: error})
                //TODO: LOG INSTANCE
        }
    }

    const togglePassword = e => {
        e.preventDefault();
        passwordRef.current.type = isPasswordHidden ? 'text' : 'password'
        setIsPasswordHidden(!isPasswordHidden)
    }
    return (
        <div className="login">
            <div className="upperSection">
                <div className="loginTitles">
                    <img src={carelonCarehubLogo} alt="" className="loginCompanyLogo"/>
                    <span className="appName">Admin Login</span>
                </div>
            </div>
            <div className="lowerSection">
                
                <div className="loginContainer">
                    <input 
                        type="text" 
                        placeholder="Domain ID" 
                        id="domainId" 
                        className="loginInput domainId" 
                        onChange={handleChange}
                        maxLength="7"
                    />
                    <div className="loginPasswordWrap">
                        <input 
                            type="password" 
                            placeholder="Password" 
                            id="password" 
                            className="loginInput password" 
                            onChange={handleChange}
                            ref={passwordRef}
                        />
                        <FontAwesomeIcon icon={ isPasswordHidden === true ? faEyeSlash : faEye} className="loginEye" onClick={togglePassword}/>
                    </div>
                    <button disabled={loading} className="loginButton" onClick={handleLogin}>Login</button>
                    { error && <span className="loginError">{error.message}</span>}
                </div>
            </div>
        </div>
    )
}

export default Login
