import { DEPARTMENTS, SITES } from '../utils/definitions'
import { format } from 'date-fns'

const ticketNumberGenerator = (department, locationDetails) => {
    let ticketNumber = ''
    const code_dept = generateDepartmentCode(department)
    const code_loc = generateLocationCode(locationDetails)
    const code_date = generateDateCode()

    ticketNumber = `${code_dept}${code_loc}${code_date}`
    return ticketNumber
}

const generateDepartmentCode = (department) => {
    let code = '';
    switch (department) {
        case DEPARTMENTS.HR:
            return code += `HR-`
            
        case DEPARTMENTS.IT:
            return code += `IT-`
            
        default:
            return code
    }
}
const generateLocationCode = (location) => {
    let code = '';
    switch (location) {
        case SITES.AGT:
            return code += `AGT-`
            
        case SITES.GLA:
            return code += `GLA-`

        case SITES.OFT:
            return code += `OFT-`

        case SITES.SMS:
            return code += `SMS-`
        default:
            return code
    }
}
const generateDateCode = () => {
    let code = '';
    const today = new Date()
    const year = format(today, 'yy')
    const month = format(today, 'MM')
    const day = format(today, 'dd')
    code = `${year}${month}${day}-`
    return code
}

export default ticketNumberGenerator