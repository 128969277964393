import './feedback.css'
import { useState, useEffect, useRef } from 'react'
import FeedbackTicketNumber from '../../components/FeedbackTicketNumber/FeedbackTicketNumber'
import FeedbackContent from '../../components/FeedbackContent/FeedbackContent'
import { getTicketByFilter } from '../../services/tickets.js'

const Feedback = () => {
    const [ticketQueueNumber, setTicketQueueNumber] = useState(null)
    const [isVerifyBtnDisabled, setIsVerifyBtnDisabled] = useState(true)
    const ticketQueueNumberRef = useRef()
    const [urlCall, setUrlCall] = useState('/tickets/getTicketsByDepartment?ticketQueueNumber=default')
    const [ticket, setTicket] = useState([])
    const [invalidMsg, setInvalidMsg] = useState('')
    const [firstMount, setFirstMount] = useState(true)
    const [isVerified, setIsVerified] = useState(false)
    const [isIMS, setIsIMS] = useState(false)

    const handleSetUrlCall = (url) => {
        setUrlCall(`/tickets/getTicketsByDepartment?ticketQueueNumber=${url?.state?.ticketQueueNumber}`)
        setFirstMount(false)
    }
    const handleChange = (e) => {
        let input = e.target.value
        input = input.toUpperCase()
        setTicketQueueNumber(input)
        setUrlCall(`/tickets/getTicketsByDepartment?ticketQueueNumber=${input}`)
        isTicketNumberInputEmpty()
    }
    const isTicketNumberInputEmpty = () => {
        if(ticketQueueNumber === '' || ticketQueueNumber === null) setIsVerifyBtnDisabled(true)
        else setIsVerifyBtnDisabled(false)
    }
    const handleVerifyTicket = e => {
        e.preventDefault()
        //is IMS Ticket
        if (IMScheck()) {
            let mounted = true
            getTicketByFilter(`/tickets/findIMSTicket?imsTicketNumber=${ticketQueueNumber}`).then( (item) => {
                if (mounted) {
                    if (item.feedbackStatus) {
                        setIsVerified(false)
                        setInvalidMsg('Sorry, the Care Hub ticket number you provided already has feedback.')
                    }
                    else {
                        //Valid IMS ticket
                        setIsIMS(true)
                        setTicket([])
                        setIsVerified(true)
                        setInvalidMsg('')
                    }
                }
            })
            return () => mounted = false
            
        }
        else {
            //Not IMS Ticket
            setIsIMS(false)
            let mounted = true
            getTicketByFilter(urlCall).then( (item) => {
                if (mounted) {
                    setTicket(item)
                    if (item.length === 0) {
                        setInvalidMsg('Sorry, the Care Hub ticket number you provided is invalid.')
                    }
                    else {
                        if (item[0].ticketStatus === "CLOSED" && item[0].feedbackStatus === false) {
                            setIsVerified(true)
                            setInvalidMsg('')
                        }
                        else if (item[0].ticketStatus === "CLOSED" && item[0].feedbackStatus === true ){
                            setIsVerified(false)
                            setInvalidMsg('Sorry, the Care Hub ticket you provided already has feedback.')
                        }
                        else if ( item[0].ticketStatus !== 'CLOSED' ){
                            setInvalidMsg('Sorry, the Care Hub ticket you provided is not yet closed.')
                            setIsVerified(false)
                        }
                    }
                }
            })
            return () => mounted = false
        }
    }
    const IMScheck = () => {
        return /^(IMS)[0-9]{7}$/.test(ticketQueueNumber) || /^(INC)[0-9]{8}$/.test(ticketQueueNumber)
    }
    // useEffect(() => {
    //     if (!firstMount) {
    //         let mounted = true
    //         getTicketByFilter(urlCall).then( (item) => {
    //             if (mounted) {
    //                 setTicket(item)
    //                 if (item.length === 0) {
    //                     setInvalidMsg('Sorry, the Care Hub ticket number you provided is invalid.')
    //                 }
    //                 else {
    //                     if (item[0].ticketStatus === "CLOSED" && item[0].feedbackStatus === false) {
    //                         setIsVerified(true)
    //                         setInvalidMsg('')
    //                     }
    //                     else if (item[0].ticketStatus === "CLOSED" && item[0].feedbackStatus === true ){
    //                         setIsVerified(false)
    //                         setInvalidMsg('Sorry, the Care Hub ticket you provided already has feedback.')
    //                     }
    //                     else if ( item[0].ticketStatus !== 'CLOSED' ){
    //                         setInvalidMsg('Sorry, the Care Hub ticket you provided is not yet closed.')
    //                         setIsVerified(false)
    //                     }
    //                 }
    //             }
    //         })
    //         return () => mounted = false
    //     }
    // }, [urlCall])
    
    return (
        <div className="feedbackPage">
            <div className="feedbackContainer">
                <div className="feedbackUpperSection">
                    <div className="feedbackTitle">
                        Carelon Care Hub Satisfaction Survey
                    </div>
                    {
                        !isVerified ? 
                        <div className="feedbackTicketNumber">
                            <span>Please enter your Care Hub ticket number:</span>
                            <div className="inputTicketNumWrapper">
                                <input className="feedbackInput" onChange={handleChange} onKeyUp={handleChange} ref={ticketQueueNumberRef} type="text"/>
                                <button className="feedbackVerifyBtn" disabled={isVerifyBtnDisabled} onClick={handleVerifyTicket}>Verify</button>
                                {/* <FeedbackTicketNumber passUrlCall={(url) => handleSetUrlCall(url)} /> */}
                            </div>
                        </div> 
                        : ''
                    }
                </div>
                <div className="feedbackLowerSection">
                    <FeedbackContent ticket={ticket} invalidMsg={invalidMsg} isIMS={isIMS} ticketQueueNumber={ticketQueueNumber} />
                </div>
            </div>
            
        </div>
    )
}

export default Feedback
