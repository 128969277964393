import './barChart.css'
import {
    BarChart,
    Legend,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Bar,
  } from "recharts";
import { forwardRef } from 'react';

const BarChartTotal = forwardRef( function BarChartSiteUtilization({data}, ref) {
    return (
        <div className='bcsuContainer' ref={ref}>
            <div className="bcWrapper">
                <BarChart width={400} height={250} data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count" fill="#8884d8" />
                </BarChart>
            </div>
            {/* <p className="bcsuTitle">Total utilization for {data[0]?.timePeriod}</p> */}
        </div>
    )
})

export default BarChartTotal
