import { serviceUrl, requestUrl } from '../utils/requestMethods.js'

export function getTicketByFilter(url) {
  let requestUrl = `${serviceUrl}${url}`
  return fetch(requestUrl)
    .then(data => data.json())
}
export const getSiteName = async (siteId) => {
  let siteName = '';
  const query = `/sites/${siteId}`
  try {
      let config = {
          headers: {
              'Content-Type': 'application/json',
          },
          withCredentials: 'same-origin'
      }
      const res = await requestUrl.get(query, config)
      const { data } = res
      siteName = data.name
      return siteName
  } catch (error) {
      console.log(error)
  }
}