import axios from "axios"

const serverMode = true;
const localhost = 'http://localhost:4000/api';
const production = 'https://careloncarehub.com/api'

export const requestUrl = axios.create({
    baseURL: (serverMode ? production : localhost),
    headers: {
        'Content-Type': 'application/json',
    },
})

export const serviceUrl = serverMode ? production : localhost

