import './sideBar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { faFileSignature, faMagnifyingGlass, faFile, faInbox } from "@fortawesome/free-solid-svg-icons"
import { Link } from 'react-router-dom'

const SideBar = ({isSideBarOpen, toggleSideBar}) => {
    return (
        <div className={`sideBar ${isSideBarOpen === true ? 'active' : ''}`} >
            <div className='sideBarContainer'>
                <div className='sideBarTopSection'>
                    <div className="sideBarTitle">
                        <h3>Care Hub Menu</h3>
                    </div>
                    <div className='sideBarClose' onClick={toggleSideBar}>
                        <FontAwesomeIcon icon={faXmark} />
                    </div>
                </div>
                <div className="sideBarContents">
                    <Link className="sideBarLink" to='/admin/tickets' onClick={toggleSideBar}>
                        <div className="sideBarItem">
                            <div className='sideBarItemIcon'>
                                <FontAwesomeIcon icon={faFile} />
                            </div>
                            <label>Active Tickets</label>
                        </div>
                    </Link>
                    <Link className="sideBarLink" to='/admin/archive' onClick={toggleSideBar}>
                        <div className="sideBarItem">
                            <div className='sideBarItemIcon'>
                                <FontAwesomeIcon icon={faInbox} />
                            </div>
                            <label>Archived Tickets</label>
                        </div>
                    </Link>
                    <Link className="sideBarLink" to='/admin/search' onClick={toggleSideBar}>
                        <div className="sideBarItem">
                            <div className='sideBarItemIcon'>
                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                            </div>
                            <label>Search Tickets</label>
                        </div>
                    </Link>
                    <Link className="sideBarLink" to='/admin/reports' onClick={toggleSideBar}>
                        <div className="sideBarItem">
                            <div className='sideBarItemIcon'>
                                <FontAwesomeIcon icon={faFileSignature} />
                            </div>
                            <label>Reports</label>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default SideBar
