import './feedbackModal.css'

const FeedbackModal = ({modalMessage}) => {

    const redirectToHome = () => {
        window.location.reload();
    }
    return (
        <div className="feedbackModal">
            <div className="feedbackModalContainer">
                    {
                        <div className="feedbackModalWrap">
                            <div className="feedbackModalForm">
                                <div className="feedbackModalTicketWrap">
                                    <div className="feedbackModalFormItem">
                                        <div className="feedbackModalTitle">
                                            Satisfaction Survey
                                        </div>
                                    </div>
                                    <div className="feedbackModalFormItem">
                                        <p>{modalMessage}</p>
                                    </div>
                                </div>
                                <button className="feedbackModalBtn" onClick={redirectToHome}>Close</button>
                            </div>
                        </div>
                    }
            </div>
        </div>
    )
}

export default FeedbackModal
