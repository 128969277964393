import React from 'react'
import { useNavigate } from "react-router-dom"
import './landing.css'
import carelonCarehubLogo from "../../assets/images/carelon_carehub_logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLocationDot } from "@fortawesome/free-solid-svg-icons"
import useFetch from '../../hooks/useFetch';

const Landing = () => {
    const navigate = useNavigate();
    const deviceLocation = JSON.parse(localStorage.getItem('location')) !== null ? JSON.parse(localStorage.getItem('location')) : null;
    const query = `/sites/${deviceLocation.location}`
    const { data } = useFetch(query);

    const handleCreateTicket = async e => {
        e.preventDefault();
        navigate('/ticket/new');
    }

    return (
        <div className="landing">
            <div className="landingUpperSection">
                <img src={carelonCarehubLogo} alt="Carelon Carehub Logo" className="landingCarehubLogo"/>
                <div className="locationWrap">
                    <FontAwesomeIcon icon={ faLocationDot } className="landingLocationIcon" />
                    <span className="landingText">{data.title}</span>
                </div>
            </div>
            <div className="landingLowerSection">
                <button className="landingButton" onClick={handleCreateTicket}>Get Ticket Number</button>
            </div>

        </div>
    )
}

export default Landing
