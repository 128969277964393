import './reports.css'
import BarChartTotal from '../../components/BarChart/BarChartTotal'
import BarChartActive from '../../components/BarChart/BarChartActive'
import PieChartIssues from '../../components/PieChart/PieChartIssues'
import PieChartDept from '../../components/PieChart/PieChartDept'

const totalTicketsData = [
    {
      name: "HR Care Hub",
      count: 6,
      timePeriod: 'last 30 days'
    }
];

const Reports = () => {
    return (
        <div className="reportsContainer">
            <h2>Reports</h2>
            
            
            <div className="reportSectionWrapper">
                <div className="reportsSection item1">
                    <div className="reportName">Total Tickets</div>
                    <div className="reportContent">
                        <BarChartTotal data={totalTicketsData} />
                    </div>
                </div>
                <div className="reportsSection item2">
                    <div className="reportName">Total Tickets Per Issue</div>
                    <div className="reportContent">
                        <PieChartIssues />
                    </div>
                </div>
                <div className="reportsSection item3">
                    <div className="reportName">Total Active VS Inactive Associates</div>
                    <div className="reportContent">
                        <BarChartActive />
                    </div>
                </div>
                <div className="reportsSection item4">
                    <div className="reportName">Total Tickets Per Department</div>
                    <div className="reportContent">
                        <PieChartDept/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reports
