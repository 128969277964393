import './ticketCard.css'
import { useContext } from 'react'
import { AuthContext } from "../../context/AuthContext";
import { TicketContext } from '../../context/TicketContext'
import { useNavigate } from 'react-router-dom';
import { format } from "date-fns";
import { requestUrl } from '../../utils/requestMethods.js'

const TicketCard = ({ticketInfo, ticketStatus}) => {
    const { user } = useContext(AuthContext)
    const { dispatch } = useContext(TicketContext);
    const navigate = useNavigate();
    const ticket = ticketInfo

    const handleCloseTicket = async e => {
        e.preventDefault();
        const payload = {
            ticketStatus: "CLOSED",
            actionTaken: {
                performedBy: user.domainId,
                performedDate: new Date(),
                action: "CLOSE TICKET",
                remarks: "",
            },
        };
        try {
            let config = {
                headers: {
                  'Content-Type': 'application/json',
                },
                withCredentials: 'same-origin'
            }
            const res = await requestUrl.put(`/tickets/${ticket._id}`, payload, config)
            dispatch({type:"SUCCESS", payload: res.data})
            navigate('/admin/tickets')
            window.location.reload();
        } catch (error) {
            if (error.response?.data !== undefined) {
                dispatch({type:"FAILURE", payload: error.response?.data})
            }
            else {
                dispatch({type:"FAILURE", payload: error})
            }
        }
    }

    const handleViewDetails = async e => {
        e.preventDefault()
        navigate(`/admin/tickets/${ticketInfo._id}`, {
            state: { ticketInfo },
        });
    }

    return (
        <div className="ticketCardItem">
            <div className="ticketCardItemType">
            </div>
            <div className="ticketCardItemBox">
                <div className="ticketCardItemBoxDesc">
                    <div className="itemBoxWrap">
                        <span className="ticketCardItemSubtitle">{ticketInfo.ticketQueueNumber}</span>
                        <br/>
                        <span className="ticketCardItemDates">
                            {`${format(
                            new Date(ticket.createdAt),
                            "MMM dd yyyy"
                            )}`}
                            <br/> {`${format(new Date(ticket.createdAt), "p")}`}
                        </span>
                    </div>
                    <div className="itemBoxWrap">
                        <label>{ticket.ticketStatus}</label>
                        <span className="ticketCardItemBoxName">{ticket.issue}</span>
                        <span className="ticketCardItemSubtitle">{ticket.additionalInformation}</span>
                        {
                            ticket.snowTicket === '' ? '' : <span className="ticketCardItemSubtitle">SNOW #: {ticket.snowTicket}</span>
                        }
                    </div>
                    <div className="itemBoxWrap">
                        <span className="ticketCardItemSubtitle">{ticket.isActive ? 'Carelon Associate' : 'Non-Carelon Associate'}</span>
                        <span className="ticketCardItemBoxName">{ticket.lastName}, {ticket.firstName}</span>
                        <span className="">{ticket.domainId}</span>
                        <span className="">{ticket.email}</span>
                        <span className="">{ticket.lineOfBusinessOrTower}</span>
                    </div>
                </div>
                <div className="ticketCardItemDetails">
                    {
                        ticketStatus === "CLOSED" ? '' 
                        :
                        <button className="ticketCardItemDetailsBtn" onClick={handleCloseTicket}>
                            Close 
                        </button>
                    }
                            <button className="ticketCardItemDetailsBtn" onClick={handleViewDetails}>
                                View 
                            </button>
                
                </div>
            </div>
        </div>
    )
}

export default TicketCard
