import { DEPARTMENTS, ROLES } from './definitions'
import { requestUrl } from '../utils/requestMethods.js'


const getInfoOfAdmin = (user, filter) => {
    let info = ''

    switch(filter) {
        case 'role': {
           info = getRoleOfUser(user.permissions)
           break;
        }
        case 'department': {
           info = getDepartmentOfUser(user.permissions)
           break;
        }
        case 'siteName': {
            info = getSiteName(user.siteId)
            info.then( (result) => {
                return result
            })
        }
        default: {
           //statements;
           break;
        }
     }
    return info
}

const getRoleOfUser = (permissions) => {
    let role = ''
    if (permissions.isHR) role = ROLES.HR
    if (permissions.isIT) role = ROLES.IT
    return role
}

const getDepartmentOfUser = (permissions) => {
    let department = ''
    if (permissions.isHR) department = DEPARTMENTS.HR
    if (permissions.isIT) department = DEPARTMENTS.IT
    return department
}

const getSiteName = async (siteId) => {
    let siteName = '';
    const query = `/sites/${siteId}`
    try {
        let config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: 'same-origin'
        }
        const res = await requestUrl.get(query, config)
        const { data } = res
        siteName = data.name
        return siteName
    } catch (error) {
        console.log(error)
    }
}

export default getInfoOfAdmin