import './search.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCode,
    faHammer
  } from "@fortawesome/free-solid-svg-icons";

const Search = () => {
    return (
        <div className='searchContainer'>
                <div className="searchHeader">
                    <span>Search Tickets</span>
                </div>
                <div className="searchContent">
                    <div className="searchContentWrapper">
                        <div className="searchContentList">
                            <FontAwesomeIcon icon={ faCode } /> This section is under development <FontAwesomeIcon icon={ faCode } />
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Search
