import './ticket.scss'
import { useContext, useState, useRef, useEffect } from "react"

import { TicketContext } from '../../context/TicketContext'
import useFetch from '../../hooks/useFetch';
import { requestUrl } from '../../utils/requestMethods.js'
import { CGS_TOWERS_ARR } from '../../utils/definitions.js'
import ticketNumberGenerator from '../../utils/ticketNumberGenerator';
import ActionModal from '../ActionModal/ActionModal'

const NewTicket = () => {
    const { loading, error, dispatch } = useContext(TicketContext);
    let res = null;

    const deviceLocation = JSON.parse(localStorage.getItem('location')) !== null ? JSON.parse(localStorage.getItem('location')) : null;
    let locationDetails = useFetch(`/sites/${deviceLocation.location}`)
    locationDetails = locationDetails.data

    const submitButtonRef = useRef()
    const [ticketCount, setTicketCount] = useState('')
    const [department, setDepartment] = useState('Human Resources')
    const [isAssociate, setIsAssociate] = useState(true);
    const [departmentConcernList, setDepartmentConcernList] = useState(['ATM Card Distribution', 'Merchandise Distribution', 'Collaterals Distribution', 'Others'])
    const [selectedConcern, setSelectedConcern] = useState(false);
    const requiresSNOWTicket = ['I need an IT Asset Replacement', 'I need to return an IT Asset', 'I need to claim IT Assets assigned to me', 'I’m having issues with my Wifi', 'I’m having issues with a software application']
    const [isFormValidated, setIsFormValidated] = useState(false);
    const [newTicket, setNewTicket] = useState(
        {
            siteId: deviceLocation.location,
            ticketStatus: 'NEW',
            ticketQueueNumber: '12345',
            department: 'Human Resources',
            issue: 'ATM Card Distribution',
            additionalInformation: '',
            snowTicket: '',
            firstName: '',
            lastName: '',
            isActive: true,
            email: '',
            domainId: '',
            lineOfBusinessOrTower: 'Delivery Excellence',
        }
    );
    const [openActionModal, setOpenActionModal] = useState(false);
    
    const query = `/tickets/getLastNumberByLocDept?siteId=${deviceLocation.location}&department=${department}`
    const ticketNumber = ticketNumberGenerator(department, locationDetails.location)
    useEffect( ()=> {
        getTicketCount()
    }, [department])

    const getTicketCount = async () => {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: 'same-origin'
            }
            const res = await requestUrl.get(query, config)
            const { ticket } = res.data
            if (ticket.length === 0) {
                setTicketCount(1)
            }
            else {
                setTicketCount(generateLastNumber(ticket) + 1)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const generateLastNumber = (ticket) => {
        const ticketNum = ticket[0]?.ticketQueueNumber
        let split = ticketNum?.split("-")
        return Number(split[3])
    }

    const toggleIsAssociate = e => {
        submitButtonRef.current.disabled = true;
        if (e.target.value === 'true') {
            setIsAssociate(true)
            setNewTicket(prev => ({...prev, email: '' }))
        }
        else {
            setIsAssociate(false)
            setNewTicket(prev => ({...prev, domainId: '', lineOfBusinessOrTower: '', email: '' }))
        }
        validateTicket(e);
    }

    const toggleEmployeeDepartment = e => {
        setNewTicket(prev => ({...prev, [e.target.id]: e.target.value }))
        validateTicket(e);
    }

    const toggleIsOthersSelected = e => {
        console.log(e.target.value)
        setSelectedConcern(e.target.value)
        setNewTicket(prev => ({...prev, additionalInformation: '' }))
        validateTicket(e);
    }

    const changeDepartment = e => {
        setDepartment(e.target.value)
        setSelectedConcern(false)
        switch(e.target.value) {
            case 'Human Resources': {
               setDepartmentConcernList(prev => (
                   ['ATM Card Distribution', 
                   'Merchandise Distribution', 
                   'Collaterals Distribution', 
                   'Others']))
               setNewTicket(prev => ({...prev, issue: 'ATM Card Distribution' }))
               break;
            }
            case 'IT': {
                setDepartmentConcernList(prev => (
                    ['Hardware Diagnostic / Troubleshooting for Laptops', 
                    'I need an IT Asset Replacement', 
                    'I need to return an IT Asset', 
                    'I need to claim IT Assets assigned to me',
                    'I’m having issues with my Wifi',
                    'My password is locked / needs reset',
                    'I’m having issues with a software application',
                    'I’m having trouble with my mobile device',
                    'I have a question',
                    'Others']))
                setNewTicket(prev => ({...prev, issue: 'Hardware Diagnostic / Troubleshooting for Laptops' }))
               break;
            }
            default: {
               //statements;
               break;
            }
         }
        validateTicket(e)
    }

    const validateTicket = e => {
        console.log(newTicket)
        if(e.target.id === 'department') {
            setDepartment(e.target.value)
        } 
        setNewTicket(prev => ({...prev, [e.target.id]: e.target.value, ticketQueueNumber: `${ticketNumber}${ticketCount}` }))
        if( (newTicket.issue.trim() !== '') && 
        ((newTicket.isActive && (newTicket.lineOfBusinessOrTower !== '' && newTicket.domainId.trim() !== '')) || newTicket.isActive === 'false') && 
        newTicket.firstName.trim() !== '' && 
        newTicket.lastName.trim() !== '' && 
        newTicket.email.trim() !== '' ) 
        setIsFormValidated(true)
        else setIsFormValidated(false)
        if ((newTicket.issue === 'Others' && newTicket.additionalInformation.trim() === '')) setIsFormValidated(false)
        console.log(requiresSNOWTicket.includes(newTicket.issue))
        console.log((newTicket.snowTicket.trim() === ''))
        if ((requiresSNOWTicket.includes(newTicket.issue) && newTicket.snowTicket.trim() === '')) setIsFormValidated(false)
    }

    const processSubmitTicket = async e => {
        e.preventDefault()
        try {
            let config = {
                headers: {
                  'Content-Type': 'application/json',
                },
                withCredentials: 'same-origin'
            }
            res = await requestUrl.post(`/tickets`, newTicket, config)
            dispatch({type:"SUCCESS", payload: res.data})
            setOpenActionModal(true);
        } catch (error) {
            if (error.response?.data !== undefined) {
                dispatch({type:"FAILURE", payload: error.response?.data})
            }
            else {
                dispatch({type:"FAILURE", payload: error})
            }
            setOpenActionModal(true);
        }
    }
    return (
        <div className="newTicket">
            <div className="ticketContainer">
                <div className="holes-top"></div>
                <h1 className="ticketSectionTitle">What is this ticket about?</h1>
                <div className="ticketItem">
                    <span className="ticketText">Which department is this ticket for?</span>
                    <select name="departmentList" className="ticketSelect" id="department" onChange={changeDepartment}>
                        <option>Human Resources</option>
                        <option>IT</option>
                    </select>
                </div>
                <div className="ticketItem">
                    <span className="ticketText">Select from the following concerns:</span>
                    <select name="hrConcernList" className="ticketSelect" id="issue" onChange={toggleIsOthersSelected}>
                        {
                            departmentConcernList && departmentConcernList.map( (item) => (
                                <option key={item} selected={(item === 'ATM Card Distribution' || item === 'Hardware Diagnostic / Troubleshooting for Laptops') ? true : false} value={item}>{item}</option>
                            ))
                        }
                    </select>
                </div>
                {
                    requiresSNOWTicket.includes(selectedConcern) ?  
                    <>
                        <div className="ticketItem">
                            <span className="ticketText">Provide the SNOW Ticket Number:</span>
                            <input id='snowTicket' type='text' className="ticketInput" onKeyUp={validateTicket} onChange={validateTicket}></input>
                        </div>
                    </> 
                    : ''
                }
                {
                    selectedConcern !== 'Others' ? '' : 
                    <>
                        <div className="ticketItem">
                            <span className="ticketText">Tell us more about this concern (optional):</span>
                            <textarea id='additionalInformation' rows='15' cols='40' onKeyUp={validateTicket} onChange={validateTicket}></textarea>
                        </div>
                    </> 
                }
                <div className="holes-lower"></div>
                <h1 className="ticketSectionTitle">Tell us about you</h1>
                <div className="ticketItem">
                    <span className="ticketText">Your first name:</span>
                    <input id='firstName' type='text' className="ticketInput" onKeyUp={validateTicket} onChange={validateTicket}></input>
                </div>
                <div className="ticketItem">
                    <span className="ticketText">Your last name:</span>
                    <input id='lastName' type='text' className="ticketInput" onKeyUp={validateTicket} onChange={validateTicket}></input>
                </div>
                <div className="ticketItem">
                    <span className="ticketText">Are you a Carelon Associate?</span>
                    <select name="isActiveList" className="ticketSelect" id="isActive" onChange={toggleIsAssociate}>
                        <option value="true">Yes, I am a Carelon Associate</option>
                        <option value="false">No, I am not a Carelon Associate</option>
                    </select>
                </div>
                {
                    isAssociate === true ? <CorporateDetails validateTicket={validateTicket} toggleEmployeeDepartment={toggleEmployeeDepartment}/> : 
                    <>
                        <div className="ticketItem personal">
                            <span className="ticketText">Your personal E-mail Address:</span>
                            <input id='email' className="ticketInput" type='text' onKeyUp={validateTicket} onChange={validateTicket}></input>
                        </div>
                    </>
                }
                
                <div className="holes-lower"></div>
                <div className="ticketItem">
                    <button ref={submitButtonRef} disabled={!isFormValidated} className="submitTicketButton" onClick={processSubmitTicket} >Submit Ticket</button>
                </div>
            </div>
            {openActionModal && (
                <ActionModal
                    loading={loading}
                    newTicket={newTicket}
                    openActionModal={setOpenActionModal}
                    res={res}
                    error={error}
                />
            )}
        </div>
    )
}

const CorporateDetails = ({validateTicket, toggleEmployeeDepartment}) => {
    return <>
        <div className="ticketItem corporate">
            <span className="ticketText"> Your Domain ID:</span>
            <input id='domainId'  className="ticketInput" type='text' maxLength="7" onKeyUp={validateTicket} onChange={validateTicket}></input>
        </div>
        <div className="ticketItem corporate">
            <span className="ticketText">Your Carelon E-mail Address:</span>
            <input id='email'  className="ticketInput" type='text' onKeyUp={validateTicket} onChange={validateTicket}></input>
        </div>
        <div className="ticketItem corporate">
            <span className="ticketText">Your Tower:</span>
            <select className="ticketSelect" id="lineOfBusinessOrTower" onChange={toggleEmployeeDepartment}>
                {
                    CGS_TOWERS_ARR && CGS_TOWERS_ARR.map( (item) => (
                        <option key={item} selected={(item === 'Delivery Excellence' ) ? true : false} value={item}>{item}</option>
                    ))
                }
            </select>
        </div>
    </>
}

export default NewTicket
