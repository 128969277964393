import './topbar.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/free-regular-svg-icons"
import { useContext } from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { AuthContext } from "../../context/AuthContext";
import getInfoOFAdmin from '../../utils/getInfoOfAdmin'


const TopBar = ({toggleSideBar, toggleUserOptions}) => {
    const { user } = useContext(AuthContext)
    let role = getInfoOFAdmin(user, 'role')
    return (
        <div className="topBarContainer">
            <div className="topBarMenuToggle" onClick={toggleSideBar}>
                <FontAwesomeIcon icon={faBars} className="topBarMenuToggleIcon" onClick={toggleSideBar} />
                <span onClick={toggleSideBar}>MENU</span>    
            </div>
            <div className="topBarTitle">
                <h3>{role} Care Hub Dashboard</h3>
            </div>
            <div className="topBarOptions" onClick={toggleUserOptions} >
                <FontAwesomeIcon icon={faUser} />
            </div>
        </div>
    )
}

export default TopBar
