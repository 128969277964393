export const ACTION_TYPES = {
    START: "START",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    LOGOUT: "LOGOUT",
};
export const DEFAULT_NAMES = {
    APP_NAME: "Carelom Care Hub",
    LOADING_MESSAGE: "Loading..."
}

export const ERROR_MESSAGE = {
    CONTACT: "Contact System Administrator",
    ERR_403: "Request failed with status code 403"
}

export const DEPARTMENTS = {
    HR: "Human Resources",
    IT: "IT",
}

export const SITES = {
    AGT: "AGT",
    GLA: "GLA",
    OFT: "OFT",
    SMS: "SMS",
}
export const ROLES = {
    HR: "HR",
    IT: "IT",
    FIN: "Finance",
}
export const CGS_TOWERS_ARR = [
    'Clinical',
    'Corporate Support',
    'Genesys Support',
    'IT Service Desk',
    'Member & Carelon Services',
    'OPS Management',
    'ORMB',
    'Provider Services',
    'QA Services',
    'UM Intake'
]
export const CGS_TOWERS_OBJ = {
    Clinical: "Clinical",
    CorporateSupport: "Corporate Support",
    GenesysSupport: "Genesys Support",
    ITServiceDesk: 'IT Service Desk',
    MemberCarelonServices: 'Member & Carelon Services',
    OPSManagement: 'OPS Management',
    ORMB: 'ORMB',
    ProviderServices: 'Provider Services',
    QAServices: 'QA Services',
    UMIntake: 'UM Intake'
}

export const TICKET_STATUS = {
    NEW: "NEW",
    CLOSED: "CLOSED"
}